import React from "react"
import { observer } from "mobx-react-lite"
import styled, { css, keyframes } from "styled-components"
import { em, rem } from "polished"

import { ReactComponent as SvgClose } from "../assets/images/icons/close.svg"
import { useStore } from "../store"
import { __ } from "../utils/translate"

const Legend = () => {
  const store = useStore()

  const closeClick = () => store.setLegend(false)

  if (!store.showLegend) return null

  return (
    <Container>
      <Surfaces>
        <Surface $type="gravel">{__(`Mixed traffic, gravel`)}</Surface>

        <Surface $type="asphalt">{__(`Mixed traffic, asphalted`)}</Surface>

        <Surface $type="bikeGravel">{__(`Cycle path, gravel`)}</Surface>

        <Surface $type="bikeAsphalt">{__(`Cycle path, asphalted`)}</Surface>
      </Surfaces>

      <Close onClick={closeClick} title={__(`Close`)}>
        <SvgClose aria-label={__(`Close`)} />
      </Close>
    </Container>
  )
}

export default observer(Legend)

const containerAnim = keyframes`
  0% { opacity: 0; transform: translateY(100%); }
  100% { opacity: 1; transform: translateY(0); }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gunmetal};
  box-shadow: ${({ theme }) => theme.bs.button};
  border-radius: ${({ theme }) => theme.br.small};
  transform-origin: bottom;
  animation: ${containerAnim} 0.5s ${({ theme }) => theme.easings.outBack};

  @media only print {
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const Surfaces = styled.ul`
  ${({ theme }) => theme.mixins.hideScrollbar()}
  ${({ theme }) => theme.fonts.set(`primary`, `medium`)}

  padding: ${em(16)} ${em(20)};
  flex-grow: 1;
  display: flex;
  font-size: ${em(13)};
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  mask: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) ${em(18)},
    rgba(0, 0, 0, 1) calc(100% - ${em(18)}),
    rgba(0, 0, 0, 0) 100%
  );

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${rem(11)};
  }
`

const Surface = styled.li`
  display: flex;
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 1em;
  }

  &::before {
    content: "";
    width: 2.5em;
    border-top: 0.4em solid;
    margin-top: 0.5em;
    margin-right: 0.4em;

    ${({ $type }) =>
      [`gravel`, `bikeGravel`].includes($type) &&
      css`
        border-top-color: ${({ theme }) => theme.colors.surfaceGravel};
      `}

    ${({ $type }) =>
      [`asphalt`, `bikeAsphalt`].includes($type) &&
      css`
        border-top-color: ${({ theme }) => theme.colors.surfaceAsphalt};
      `}

  ${({ $type }) =>
      [`gravel`, `asphalt`].includes($type) &&
      css`
        border-top-style: dashed;
      `}
  }
`

const Close = styled.button.attrs({ type: `button` })`
  width: ${em(44)};
  flex-shrink: 0;

  @media only print {
    visibility: hidden;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  &:active {
    opacity: 0.6;
  }

  svg {
    width: 100%;
    height: 100%;
    padding: ${em(16)};
    display: block;
  }
`
