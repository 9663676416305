import React, { useEffect, Suspense, lazy } from "react"
import { observer } from "mobx-react-lite"
import { reaction } from "mobx"
import { useMediaQuery } from "@react-hook/media-query"
import styled, { css } from "styled-components"
import { rem, rgba } from "polished"

import Map from "./Map"
import Controls from "./Controls"
import Menu from "./Menu"
import ViewToggle from "./ViewToggle"
import Elevations from "./Elevations/Index"
import Site from "./Site/Index"
import SiteTooltip from "./SiteTooltip"
import Traffic from "./Traffic/Index"
import { useStore } from "../store"
import mq from "../theme/mq"
import { postEmbedMessage } from "../utils/embedMessage"
import embedParams from "../config/embedParams"

const Embed = lazy(() => import(`./Embed`))
const SiteCardMobileWrap = lazy(() => import(`./SiteCardMobileWrap`))

const Base = () => {
  const store = useStore()
  const isMobile = useMediaQuery(mq.mobileDown)
  const isDesktop = !isMobile

  useEffect(() => {
    store.setDisplaySite(false)
    if (isMobile && store.menu) store.setMenu(false)
  }, [isMobile])

  useEffect(() => {
    if (isMobile && store.menu) store.setMenu(false)
    if (!store.fullscreen && store.print) store.setPrint(false)
    if (store.fullscreen && store.legend && isMobile) store.setLegend(false)
  }, [store.fullscreen])

  useEffect(() => {
    if (store.pointActiveId) {
      if (isDesktop) {
        store.setMenu(true)
        store.setDisplaySite(true)
      }
    }
  }, [store.pointActiveId])

  useEffect(() => {
    if (store.traffic && isDesktop) store.setMenu(true)
  }, [store.traffic])

  useEffect(() => {
    if (isMobile) window.dispatchEvent(new Event(`resize`)) // fixes Mapbox resize issue
  }, [store.print, store.trafficMobileMap])

  useEffect(() => {
    const reactionMeasureActive = reaction(
      () => store.measure,
      () => {
        if (store.measure) store.setLegend(false)
      }
    )

    const reactionLegendActive = reaction(
      () => store.legend,
      () => {
        if (store.legend) store.setMeasure(false)
      }
    )

    const reactionFullscreen = reaction(
      () => store.fullscreen,
      () => {
        postEmbedMessage(`setFullscreen`, store.fullscreen)
      }
    )

    return () => {
      reactionMeasureActive()
      reactionLegendActive()
      reactionFullscreen()
    }
  }, [])

  return (
    <Container>
      <Suspense fallback={null}>
        {store.share && (
          <EmbedWrap>
            <Embed />
          </EmbedWrap>
        )}
      </Suspense>

      <MapWrap $menu={!isMobile && store.menu && !store.print}>
        <Map />

        {isMobile && !store.menu && (
          <ControlsMobile>
            <Controls />
          </ControlsMobile>
        )}
      </MapWrap>

      {store.elevations && store.pointElevationsAvailable && (
        <ElevationsWrap $menu={store.menu} $print={store.print}>
          <Elevations />
        </ElevationsWrap>
      )}

      {(isDesktop || (isMobile && store.fullscreen)) && !store.print && (
        <MenuWrap $menu={store.menu}>
          <Menu />
        </MenuWrap>
      )}

      {isDesktop && (
        <ControlsDesktop $menu={store.menu} $print={store.print}>
          <Controls />
        </ControlsDesktop>
      )}

      {isMobile &&
        store.displaySite &&
        store.pointActive &&
        !store.trafficMobileMap && (
          <SiteWrap>
            <Site />
          </SiteWrap>
        )}

      {isMobile &&
        store.traffic &&
        !store.trafficMobileMap &&
        embedParams.traffic && (
          <TrafficWrap>
            <Traffic />
          </TrafficWrap>
        )}

      {isMobile &&
        !store.displaySite &&
        !store.menu &&
        !store.traffic &&
        !store.elevations &&
        !store.print &&
        store.pointActive && (
          <SiteCardWrap>
            <Suspense fallback={null}>
              <SiteCardMobileWrap />
            </Suspense>
          </SiteCardWrap>
        )}

      {isMobile &&
        store.fullscreen &&
        !store.print &&
        !store.measure &&
        !store.showLegend &&
        !store.elevations && (
          <ViewToggleWrap $isTraffic={store.traffic}>
            <ViewToggle />
          </ViewToggleWrap>
        )}

      {store.mapFeatureHover && <SiteTooltip />}
    </Container>
  )
}

export default observer(Base)

const Container = styled.main`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;

  @media ${({ theme }) => theme.mq.mobileDown} {
    display: flex;
    flex-direction: column;
  }
`

const MapWrap = styled.div`
  @media ${({ theme }) => theme.mq.mobileUp} {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    transition: transform 0.3s ${({ theme }) => theme.easings.default};

    ${({ $menu }) =>
      $menu &&
      css`
        transform: translateX(calc(clamp(${rem(320)}, 30%, ${rem(480)}) / 2));
      `}
  }

  @media ${({ theme }) => theme.mq.mobileDown} {
    flex-grow: 1;
    order: 2;
    position: relative;
    z-index: 1;
  }
`

const MenuWrap = styled.div`
  @media ${({ theme }) => theme.mq.mobileUp} {
    width: clamp(${rem(320)}, 30%, ${rem(480)});
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    transform: translateX(calc(-100% + ${rem(10)}));
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 ${rem(4)} ${rem(4)}
      ${({ theme }) => rgba(theme.colors.black, 0.25)};
    transition: transform 0.3s ${({ theme }) => theme.easings.default};

    ${({ $menu }) =>
      $menu &&
      css`
        transform: translateX(0);
      `}
  }

  @media ${({ theme }) => theme.mq.mobileDown} {
    order: 1;

    ${({ $menu }) =>
      $menu &&
      css`
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        background-color: ${({ theme }) => theme.colors.white};
      `}
  }
`

const ControlsMobile = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  pointer-events: none;
`

const ControlsDesktop = styled.div`
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  left: ${rem(10)};
  overflow: hidden;
  pointer-events: none;
  transition: left 0.3s ${({ theme }) => theme.easings.default};
  will-change: left;

  ${({ $menu }) =>
    $menu &&
    css`
      left: clamp(${rem(320)}, 30%, ${rem(480)});
    `}

  ${({ $print }) =>
    $print &&
    css`
      left: 0;
    `}
`

const SiteWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
`

const TrafficWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 60;
  top: 0;
  left: 0;
`

const SiteCardWrap = styled.div`
  width: 100%;
  padding: 0 ${rem(70)};
  position: absolute;
  z-index: 15;
  bottom: ${rem(8)};
  left: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;

  @media ${({ theme }) => theme.mq.xsmallDown} {
    padding: 0 ${rem(60)} 0 ${rem(8)};
  }

  > * {
    max-width: ${rem(420)};
    pointer-events: auto;

    @media ${({ theme }) => theme.mq.xsmallDown} {
      width: 100%;
      max-width: auto;
    }
  }
`

const ElevationsWrap = styled.div`
  height: clamp(${rem(120)}, 25%, ${rem(240)});
  position: absolute;
  z-index: 15;
  bottom: ${rem(30)};
  left: ${rem(24)};
  right: ${rem(90)};

  @media ${({ theme }) => theme.mq.xxlargeDown} {
    bottom: ${rem(8)};
    right: ${rem(70)};
  }

  @media ${({ theme }) => theme.mq.mobileDown} {
    left: ${rem(8)};
  }

  ${({ $menu, $print }) =>
    $menu &&
    !$print &&
    css`
      left: calc(clamp(${rem(320)}, 30%, ${rem(480)}) + ${rem(20)});
    `}

  ${({ $print }) =>
    $print &&
    css`
      bottom: auto;
      top: ${rem(10)};
      left: ${rem(10)};

      @media only print {
        height: ${rem(130)};
        right: ${rem(10)};
      }
    `}
`

const ViewToggleWrap = styled.div`
  width: 100%;
  position: absolute;
  z-index: ${({ $isTraffic }) => ($isTraffic ? `61` : `10`)};
  bottom: ${rem(8)};
  left: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;

  @media ${({ theme }) => theme.mq.xxsmallDown} {
    padding-right: ${rem(70)};
    left: ${rem(8)};
  }

  > * {
    pointer-events: auto;
  }
`

const EmbedWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 70;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => rgba(theme.colors.black, 0.8)};
`
